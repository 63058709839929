import { createAsyncThunk } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getPatients = createAsyncThunk('patient/getPatients', async ({ search, limit, page, archived, startDate, endDate, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search
            ? { limit, page, archived, startDate, endDate }
            : startDate && endDate
                ? { search, limit, page, archived, startDate, endDate }
                : { search, limit, page, archived });
        const response = await apiKey.get(`/patients?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getOnePatient = createAsyncThunk('patient/getOnePatient', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/patients/${id}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createPatient = createAsyncThunk('patient/createPatient', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/patients`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const editPatient = createAsyncThunk('patient/editPatient', async ({ id, params }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/patients/${id}`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deletePatient = createAsyncThunk('patient/deletePatient', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.delete(`/patients/${id}`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const archivePatient = createAsyncThunk('patient/archivePatient', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/patients/${id}/archive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const unArchivePatient = createAsyncThunk('patient/unArchivePatient', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/patients/${id}/unarchive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
